import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../layout'

import BackgroundContainer from '../../../components/image/background-container'
import TitleContainer from '../../../components/common/title-container'
import Title from '../../../components/common/title'
import PageContainer from '../../../components/common/page-container'
import michael from '../../../assets/images/about/michael.jpg'
import Link from '../../../components/common/link'
import DiscreetLink from '../../../components/common/discreet-link'
import Subtitle from "../../../components/common/subtitle";
import FooterTitle from "../../../components/common/footer-title";

export default (props) => (
    <Layout title="Technical Leadership - North East based technical leadership support">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Technical Leadership</Title>
                <Subtitle>Your technology executive</Subtitle>
            </TitleContainer>
        </BackgroundContainer>

        <PageContainer>
            <h2>Technology leadership for your company</h2>
            <p>You know your business, product and the market inside out. Your development team or outsourced partner know how to develop features.</p>
            <p>We can help ensure that the two are aligned.</p>
            <p>We can also ensure that your team and current processes are setup to deliver what the business needs now, and in the future. We do this by ensuring teams not only build products which solve the immediate needs of the business, but that they do so in a way that is flexible, robust, and scalable. Finding the balance between getting a feature out the door, and ensuring it is going to cope with the future needs and demands of the business.</p>
            <p>Our expertise is in supporting software-as-a-serice businesses; we know how to leverage cloud services, we know how to setup and run development teams, we know the challenges you are likely to face as you scale your product, business and team.</p>
            <h2>Supporting your in-house or outsourced team</h2>
            <p>As your technology partners, providing a technical leadership role, we can support and mentor your existing in house or outsourced team.</p>
            <p>Most businesses start with a small, hard-working and talented team. As things start to grow, you may find your development team don't have someone to lead them. Depending on the size of your team, they might have a team lead, or a manager who helps to manage the workload, undertakes project management duties or provides some line manager support. But that isn't the same as someone providing high-level technical leadership and expertise.</p>
            <p>By partnering with packagedby we can provide this level of expertise. We can support your team, ensuring they are doing things the right way. We can mentor your team, and help them work through challenging problems. We can also advise on the right way to go about solving business problems, while still ensuring your team are learning and growing at the same time.</p>
            <h2>Our expertise, in your business or on your board</h2>
            <p>Our technical director, <DiscreetLink href="https://michaelpeacock.co.uk" title="North East Consultant CTO">Michael Peacock</DiscreetLink>, is an experienced CTO, who provides both high level technical leadership, and hands on development services to clients. He has worked with a number of companies as their CTO, both in a full-time capacity and in consultancy roles. He has worked with freelance teams, in-house teams, companies looking to build teams, and off-shore teams. In these roles, Michael provides leadership to those teams, assurance to the management of the company, and helps bridge the gap between engineering and management. He is also an AWS Certified Solutions Architect. You can learn more about Michael on our <Link to="/about" title="North East Technical Consultants">team page</Link>.</p>

        </PageContainer>
        <BackgroundContainer header={props.data.footer}>
            <TitleContainer>
                <FooterTitle>Need some technical leadership?</FooterTitle>

                <Link to="/contact" className="btn btn-lg mt-3 btn-primary">
                    Get in touch
                </Link>
            </TitleContainer>
        </BackgroundContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "services/leadership.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footer: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
